exports.components = {
  "component---src-components-recipe-page-tsx": () => import("./../../../src/components/RecipePage.tsx" /* webpackChunkName: "component---src-components-recipe-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/Contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/Newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/ThankYou.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-unsubscribe-jsx": () => import("./../../../src/pages/unsubscribe.jsx" /* webpackChunkName: "component---src-pages-unsubscribe-jsx" */)
}

