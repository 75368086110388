import styled from "styled-components"

const H1 = styled.h1`
  font-size: 2.5em;
  text-align: center;
  align-self: center;
  margin-bottom: 10px;
  margin: 15px 0 0 0;
`

const H2 = styled.h2`
  font-size: 1.5em;
  text-align: center;
  align-self: center;
  margin-bottom: 10px;
`

export { H1, H2 }
